import type { initializeApiDataRouteHandler as TinitializeApiDataRouteHandler } from 'o365.pwa.declaration.sw.routehandlers.api.data.handler.d.ts';
import type { initializeApiDataExportRouteHandler as TinitializeApiDataExportRouteHandler } from 'o365.pwa.declaration.sw.routehandlers.api.data.export.handler.d.ts';
import type { initializeApiFileRouteHandler as TinitializeApiFileRouteHandler } from 'o365.pwa.declaration.sw.routehandlers.api.file.d.ts';
import type { initializeApiPwaRouteHandler as TinitializeApiPwaRouteHandler } from 'o365.pwa.declaration.sw.routehandlers.api.pwa.d.ts';
import type { initializeWebResourceRouteHandler as TinitializeWebResourceRouteHandler } from 'o365.pwa.declaration.sw.routehandlers.webResources.d.ts';
import type { initializeDefaultRouteHandler as TinitializeDefaultRouteHandler } from 'o365.pwa.declaration.sw.routehandlers.initializeDefaultRouteHandler.d.ts';



// Types for the RouteHandlers object
type RouteHandlers = {
    initializeDefaultRouteHandlers: (customRouteHandlers?: Array<Function>) => void;
    initializeApiDataRouteHandler: typeof TinitializeApiDataRouteHandler;
    initializeApiDataExportRouteHandler: typeof TinitializeApiDataExportRouteHandler;
    initializeApiFileRouteHandler: typeof TinitializeApiFileRouteHandler;
    initializeApiPwaRouteHandler: typeof TinitializeApiPwaRouteHandler;
    initializeWebResourceRouteHandler: typeof TinitializeWebResourceRouteHandler;
    initializeDefaultRouteHandler: typeof TinitializeDefaultRouteHandler;
}

export type { RouteHandlers }